export const EVENT_CLOSE_CHECK = "event_close_check";
export const EVENT_DAY_CLOSE_CHECK = "event_day_close_check";
export const USER_AUTHORIZATION = "user_authorization";
export const AUTHORIZATION = "authorization";
export const POPUPSHOW = "erp_popup_show";


export function setStorage(key, value) {
    localStorage.setItem(key, value)
}
export function getStorage(key) {
    return localStorage.getItem(key)
}
export function remove(key) {
    return localStorage.removeItem(key)
}

export function clear() {
    return localStorage.clear();
}

export default {
    EVENT_CLOSE_CHECK, EVENT_DAY_CLOSE_CHECK, USER_AUTHORIZATION,AUTHORIZATION,POPUPSHOW
};
