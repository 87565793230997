<template>
  <main class="corpJoinMain w1320">
    <section v-if="isPopup == true"> 
      <aside class="popup serviceEnd">
        <div class="popup__wrap">
          <div class="popup__wrap--tit">
            <span>기업회원 서비스 종료 안내</span>
            <h2>케어네이션 기업회원 서비스가 2024.12.13(금) 일자로 종료됩니다.</h2>
          </div>
          <div class="popup__wrap--info">
            <p>
              기존 기업회원 이용자는 케어네이션 신청인 앱 신규 가입을 통해 동일한 간병 서비스 이용이
              가능합니다.
            </p>
            <p>
              서비스 종료로 불편함을 드린 점 양해 말씀 드리며 더 나은 돌봄 문화를 만들기 위해
              노력하겠습니다. <span>감사합니다.</span>
            </p>
          </div>
          <div class="popup__wrap--btn">
            <button type="button" @click="closePopup()">확인</button>
          </div>
        </div>
      </aside>
    </section>
    <h1 class="a11y-hidden">케어네이션</h1>
    <section class="subBg corpJoin">
      <div class="commonWrap">
        <h2 class="sectionTit">기업회원가입</h2>
        <p class="subBanPar">
          4년 연속 소비자만족도 1위 돌봄앱<br />
          당신의 걱정을 덜어드릴게요.
        </p>
      </div>
    </section>
    <section>
      <div class="commonWrap">
        <article class="corpJoin__top">
          <div class="doubleTxt">
            <span class="right">* 인증 케어메이트 기준</span>
          </div>
          <ul class="clearfix">
            <li>
              <h3>
                4년 연속<br />
                소비자만족도 1위 돌봄앱 케어네이션!
              </h3>
              <p>
                서울에서 제주까지 365일 24시간 매칭이 가능합니다. 케어네이션이 든든한 파트너가
                되어드릴게요!
              </p>
            </li>
            <li>
              <h4><mark>가입 케어메이트 현황</mark></h4>
              <div class="corpJoin__top--figure">
                <img src="@/assets/img/corpJoinFigure.png" alt=".." aria-hidden />
              </div>
              <div class="corpJoin__top--box">
                <strong>총 {{ caremateData.cgs_users_total }}명</strong>
              </div>
            </li>
            <li>
              <h4><mark>케어메이트 내·외국인 비율</mark></h4>
              <div class="corpJoin__top--chart">
                <canvas ref="chart"></canvas>
              </div>
              <dl class="corpJoin__top--box clearfix">
                <div>
                  <dt class="legend01">내국인</dt>
                  <dd>
                    <strong>{{ caremateData.local_rate }}</strong
                    >%
                  </dd>
                </div>
                <div>
                  <dt class="legend02">외국인</dt>
                  <dd>
                    <strong>{{ caremateData.foreigner_rate }}</strong
                    >%
                  </dd>
                </div>
              </dl>
            </li>
            <li>
              <h4><mark>케어메이트 성별 비율</mark></h4>
              <div class="corpJoin__top--bar">
                <div class="chartBar">
                  <div class="chartBar__bg orange">
                    <div
                      :style="{ height: caremateData.woman_rate + '%' }"
                      class="chartBar__bg--color"
                    ></div>
                  </div>
                  <div class="chartBar__bg blue">
                    <div
                      :style="{ height: caremateData.man_rate + '%' }"
                      class="chartBar__bg--color"
                    ></div>
                  </div>
                </div>
              </div>
              <dl class="corpJoin__top--box clearfix">
                <div>
                  <dt class="legend03">여성</dt>
                  <dd>
                    <strong>{{ caremateData.woman_rate }}</strong
                    >%
                  </dd>
                </div>
                <div>
                  <dt class="legend04">남성</dt>
                  <dd>
                    <strong>{{ caremateData.man_rate }}</strong
                    >%
                  </dd>
                </div>
              </dl>
            </li>
          </ul>
        </article>
        <article class="corpJoin__bottom">
          <div class="corpJoin__bottom--info">
            <h3>가입 전 준비물!</h3>
            <p>
              사업자등록증 이미지 파일이 필요해요.<br />
              승인까지는 영업일 기준 1~3일 내로 처리돼요.
            </p>
          </div>
          <div class="corpForm" ref="agreeSection">
            <div class="corpForm__agreeTit">
              <h3>약관동의</h3>
            </div>
            <ul class="corpForm__agreeInfo">
              <li>
                <div class="corpFormSelItem">
                  <input type="checkbox" id="agreeTotal" name="agreeTotal" v-model="totalCheck" />
                  <label for="agreeTotal" @click="termsAllChk()">전체 동의하기</label>
                </div>
                <ul class="corpForm__agreeInfo--depth02">
                  <!-- <li class="corpFormSelItem aco" :class="{'on' : termsToggle[0]}"> -->
                  <li class="corpFormSelItem aco" :class="termsToggle[0] ? 'on' : ''">
                    <input
                      type="checkbox"
                      id="agree01"
                      name="agree"
                      v-model="userData.companyAgree"
                    />
                    <label for="agree01"
                      >케어네이션 서비스 이용약관 - 기업회원
                      <span class="txtRed">(필수)</span>
                    </label>
                    <button type="button" @click="termsOnoff(0)">
                      {{ termsToggle[0] ? "접기" : "보기" }}
                    </button>
                    <div class="corpFormSelItem__txt" v-html="termsData[3]?.content"></div>
                  </li>
                  <li class="corpFormSelItem aco" :class="{ on: termsToggle[1] }">
                    <input type="checkbox" id="agree02" name="agree" v-model="userData.privacy" />
                    <label for="agree02"
                      >케어네이션 개인정보 처리방침 - 기업회원
                      <span class="txtRed">(필수)</span>
                    </label>
                    <button type="button" @click="termsOnoff(1)">
                      {{ termsToggle[1] ? "접기" : "보기" }}
                    </button>
                    <div class="corpFormSelItem__txt" v-html="termsData[0]?.content"></div>
                  </li>
                </ul>
              </li>
              <li>
                <div class="corpFormSelItem">
                  <h4>마케팅 정보 수신 동의 <span>(선택)</span></h4>
                </div>
                <ul class="corpForm__agreeInfo--depth02">
                  <li class="corpFormSelItem aco" :class="{ on: termsToggle[2] }">
                    <input
                      type="checkbox"
                      id="marketing01"
                      name="marketing"
                      v-model="userData.marketingAgree"
                    />
                    <label for="marketing01">광고성 정보 발송 목적 개인정보 이용 동의서</label>
                    <button type="button" @click="termsOnoff(2)">
                      {{ termsToggle[2] ? "접기" : "보기" }}
                    </button>
                    <div class="corpFormSelItem__txt" v-html="termsData[2]?.content"></div>
                  </li>
                </ul>
              </li>
            </ul>
            <strong
              class="txtRed"
              v-if="userData.companyAgree !== true || userData.privacy !== true"
              >※ 필수 동의에 모두 체크해주세요</strong
            >
          </div>
          <div class="corpForm" ref="infoSection">
            <div class="corpForm__tit">
              <h3>계정정보</h3>
            </div>
            <div class="corpForm__width50per">
              <div class="corpFormBewItem clearfix">
                <h4>이메일 아이디 <span class="txtRed">*</span></h4>
                <label>
                  <input
                    type="text"
                    placeholder="이메일 아이디를 입력해주세요"
                    v-model="userData.email"
                  />
                </label>
                <button type="button" @click="checkDuplicateId()">중복 확인</button>
              </div>
            </div>
            <ul class="corpForm__between clearfix">
              <li class="corpFormBewItem">
                <label for="passowrd01"
                  >비밀번호 <span class="txtRed">*</span>
                  <small>(비밀번호가 복잡할수록 계정 보안이 강화됩니다)</small></label
                >
                <input
                  type="password"
                  id="passowrd01"
                  name="passowrd01"
                  placeholder="규칙에 맞게 비밀번호를 입력해주세요"
                  v-model="userData.password"
                />
              </li>
              <li class="corpFormBewItem">
                <label for="passowrd02">비밀번호 확인 <span class="txtRed">*</span></label>
                <input
                  type="password"
                  id="passowrd02"
                  name="passowrd02"
                  placeholder="비밀번호를 다시 입력해주세요"
                  v-model="userData.passwordConfirmation"
                />
              </li>
            </ul>
            <div class="corpForm__info mt20">
              <h4>[안전한 비밀번호 설정방법]</h4>
              <p>
                - 영문+(숫자 또는 특수 문자) 조합 시 최소 10자리 이상, 영문+숫자+특수문자 조합 시
                최소 8자리 이상
              </p>
              <p>- 연속적인 숫자 및 전화번호 사용 불가</p>
            </div>
          </div>
          <div class="corpForm" ref="registSection">
            <div class="corpForm__tit">
              <h3>사업자등록증 정보</h3>
            </div>
            <ul class="corpForm__width50per">
              <li class="corpFormBewItem clearfix">
                <h4>
                  사업자등록번호 <span class="txtRed">*</span>
                  <small>(10자리)</small>
                </h4>
                <label>
                  <input
                    type="text"
                    placeholder="사업자등록번호를 입력해주세요"
                    v-model="userData.registrationNumber"
                    maxlength="10"
                  />
                </label>
                <button type="button" @click="authBusiNum(userData.registrationNumber)">
                  인증
                </button>
              </li>
              <li class="corpFormBewItem clearfix">
                <h4>
                  사업자등록증 사본 <span class="txtRed">*</span>
                  <small>(이미지)</small>
                </h4>
                <div class="corpFormBewItem__fileBox clearfix">
                  <input
                    type="text"
                    class="corpFormBewItem__fileBox--name"
                    :placeholder="
                      fileName ? fileName : '사업자등록증 사본(이미지)을 업로드해주세요'
                    "
                    disabled
                  />
                  <label for="file">파일 등록</label>
                  <input
                    type="file"
                    id="file"
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    ref="fileInput"
                  />
                  <div>
                    <p>- 이미지 첨부 제한 : MB / 20MB</p>
                    <p>- 이미지 허용 확장자 : JPG, JPEG, PNG, GIF, PDF</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="corpForm" ref="companySection">
            <div class="corpForm__tit">
              <h3>기업정보</h3>
            </div>
            <ul class="corpForm__between clearfix">
              <li class="corpFormBewItem">
                <h4>기업분류 <span class="txtRed">*</span></h4>
                <!-- 기업 분류를 선택하고 난 후에 dropBox 에 sel 추가 -->
                <div class="dropBox" tabindex="1" ref="dropBox">
                  {{ userData.pickedCompType }}
                  <ul>
                    <li @click="compTypePick('협회')">협회</li>
                    <li @click="compTypePick('병원')">병원</li>
                    <li @click="compTypePick('요양원')">요양원</li>
                    <li @click="compTypePick('복지관')">복지관</li>
                    <li @click="compTypePick('직접 입력')">직접 입력</li>
                  </ul>
                </div>
              </li>
              <li class="corpFormBewItem">
                <label for="classification02"></label>
                <input
                  type="text"
                  id="classification02"
                  name="classification02"
                  placeholder="기업 분류를 직접 입력해주세요"
                  disabled
                  ref="directTxt"
                  v-model="userData.writeCompType"
                />
              </li>
            </ul>
            <ul class="corpForm__between clearfix">
              <li class="corpFormBewItem mt24">
                <label for="companyName">기업명 <span class="txtRed">*</span></label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="기업명을 입력해주세요"
                  v-model="userData.companyName"
                />
              </li>
              <li class="corpFormBewItem mt24">
                <label for="Representative">대표자명 <span class="txtRed">*</span></label>
                <input
                  type="text"
                  id="Representative"
                  name="Representative"
                  placeholder="대표자명을 입력해주세요"
                  v-model="userData.owner"
                />
              </li>
              <li class="corpFormBewItem">
                <label for="tel">유선전화번호 <span class="txtRed">*</span></label>
                <input
                  type="text"
                  id="tel"
                  name="tel"
                  placeholder="유선전화번호를 입력해주세요"
                  v-model="userData.telephone"
                  @input="filterNonNumericInput"
                />
              </li>
            </ul>
          </div>
          <div class="corpForm" ref="managerSection">
            <div class="corpForm__tit">
              <h3>담당자 정보</h3>
            </div>
            <div class="corpForm__width50per">
              <div class="corpFormBewItem clearfix">
                <h4>담당자 이름 <span class="txtRed">*</span></h4>
                <label>
                  <input
                    type="text"
                    placeholder="담당자 이름을 입력해주세요"
                    v-model="userData.name"
                  />
                </label>
              </div>
            </div>
            <ul class="corpForm__between clearfix">
              <li class="corpFormBewItem">
                <label for="countersignature">근무부서 </label>
                <input
                  type="text"
                  id="countersignature"
                  name="countersignature"
                  placeholder="근무부서를 입력해주세요"
                  v-model="userData.workDepartment"
                />
              </li>
              <li class="corpFormBewItem">
                <label for="position">직책 </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  placeholder="직책을 입력해주세요"
                  v-model="userData.workPosition"
                />
              </li>
              <li class="corpFormBewItem clearfix betwBtn">
                <h4>담당자 휴대전화 번호 <span class="txtRed">*</span></h4>
                <label>
                  <input
                    type="text"
                    placeholder="'-' 없이 숫자로 입력해주세요 "
                    v-model="userData.phone"
                    :disabled="disBox.phoneTxt"
                    maxlength="11"
                    @input="filterNonNumericInput"
                  />
                </label>
                <button type="button" @click="sendSNSCode()" :disabled="disBox.phoneBtn">
                  인증번호 발송
                </button>
              </li>
              <li class="corpFormBewItem clearfix betwBtn" v-if="certifiOn">
                <h4>인증번호</h4>
                <label>
                  <input
                    type="text"
                    placeholder="인증번호 4자리 숫자 입력해주세요"
                    v-model="userData.smsCode"
                    :disabled="disBox.certiTxt"
                  />
                  <span>3:00</span>
                </label>
                <button type="button" @click="certSMSCode()" :disabled="disBox.certiBtn">
                  인증번호 확인
                </button>
              </li>
            </ul>
            <div class="corpForm__txt">
              <p>- 회원가입 신청 승인 여부의 알림만 발송돼요.</p>
            </div>
          </div>
          <div class="corpForm">
            <div class="corpForm__tit">
              <h3>알림 수신 정보</h3>
            </div>
            <div class="corpForm__width50per">
              <div class="corpFormBewItem clearfix">
                <h4>알림 받을 전화번호</h4>
                <label>
                  <input
                    type="text"
                    placeholder="'-' 없이 숫자로 입력해주세요"
                    v-model="userData.alertPhone"
                  />
                </label>
                <div>
                  <p>- 가입신청승인 이외의 모든 상태 정보의 알림이 발송돼요.</p>
                  <p>- 정보를 입력하지 않으면 담당자 번호로 모든 알림이 가요.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="corpForm funnels" ref="funnelSection">
            <div class="corpForm__tit">
              <h3>유입경로</h3>
            </div>
            <div class="corpForm__subTit">
              <h4>
                유입경로 <span class="txtRed">*</span>
                <small class="txtRed">중복 선택 가능</small>
              </h4>
            </div>
            <ul class="corpForm__sel clearfix">
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels01"
                  name="funnels"
                  v-model="userData.joinChannel[0]"
                />
                <label for="funnels01">검색 (네이버 / 다음 / 구글) </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels02"
                  name="funnels"
                  v-model="userData.joinChannel[1]"
                />
                <label for="funnels02">인스타그램 / 페이스북 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels03"
                  name="funnels"
                  v-model="userData.joinChannel[2]"
                />
                <label for="funnels03">블로그 / 카페 / 지식인 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels04"
                  name="funnels"
                  v-model="userData.joinChannel[3]"
                />
                <label for="funnels04">TV CF </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels05"
                  name="funnels"
                  v-model="userData.joinChannel[4]"
                />
                <label for="funnels05">유튜브 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels06"
                  name="funnels"
                  v-model="userData.joinChannel[5]"
                />
                <label for="funnels06">지하철 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels07"
                  name="funnels"
                  v-model="userData.joinChannel[6]"
                />
                <label for="funnels07">라디오 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels08"
                  name="funnels"
                  v-model="userData.joinChannel[7]"
                />
                <label for="funnels08">병원 / 요양원 소개 </label>
              </li>
              <li class="corpFormSelItem">
                <input
                  type="checkbox"
                  id="funnels09"
                  name="funnels"
                  v-model="userData.joinChannel[8]"
                />
                <label for="funnels09">지인추천</label>
              </li>
              <li class="corpFormSelItem" @click="channelEtcReset()">
                <input
                  type="checkbox"
                  id="funnels10"
                  name="funnels"
                  v-model="userData.joinChannel[9]"
                />
                <label for="funnels10">기타</label>
              </li>
            </ul>
            <textarea
              placeholder="다른 경로를 입력해주세요"
              v-model="userData.join_channel_etc"
              :disabled="userData.joinChannel[9] !== true"
            ></textarea>
          </div>

          <div class="corpForm__btn mt64">
            <button type="button" class="btnSolid" @click="validationCheck()">가입 신청하기</button>
          </div>
        </article>
        <article class="coperInfo__noti">
          <h5>안내사항</h5>
          <p>
            가입 신청하기가 완료되면 승인심사가 진행됩니다. 승인심사의 경우 , 영업일 기준 1~2일정도
            소요되며, 보다 빠른 승인심사를 희망하시는 경우 케어네이션 고객센터(1811-5949)로
            문의해주세요.
          </p>
        </article>
      </div>
    </section>
    <aside class="popup" v-if="pagepopup">
      <div class="popup__wrap basic">
        <div class="popup__wrap--tit">
          <h2>알림</h2>
          <p>{{ alertMsg }}</p>
        </div>
        <div class="popup__wrap--btn">
          <button type="button" class="btnSolid" @click="popupOff()">확인</button>
        </div>
      </div>
    </aside>
  </main>
</template>

<script>
import Api from "@/api/index";
import * as Utils from "@/constants/utils";
import Chart from "chart.js";
export default {
  data() {
    return {
      isPopup: true,
      // ##케어메이트 현황 데이터
      caremateData: {
        cgs_users_total: 0,
        foreigner_rate: 0,
        local_rate: 0,
        man_rate: 0,
        woman_rate: 0,
      },
      alertMsg: "", // ##알림 메세지
      errLoca: "", // ##미입력 또는 에러 위치
      myChart: null, // ##케어메이트 내/외국인 비율 차트 기본값
      termsToggle: { 0: false, 1: false, 2: false }, // ##약관 접기펴기
      termsData: [], // ##약관 데이터
      totalCheck: false, // ## 전체 체크
      fileName: "", // 파일 이름을 저장할 변수
      file: null, // 선택한 파일을 저장할 변수

      certifiOn: false, // ##인증번호 확인란 렌더링
      disBox: {
        phoneTxt: false,
        phoneBtn: false,
        certiTxt: false,
        certiBtn: false,
      },
      certifiNum: 0,
      pagepopup: false,
      // 회원가입 전송할 사용자 데이터
      userData: {
        companyAgree: false, //# 기업정보 약관 동의
        privacy: false, //# 개인정보 처리방침 동의
        marketingAgree: false, //# 마케팅 동의
        busiRegiNum: null, //# 사업자 등록 번호
        email: "", //# 이메일
        password: "", //# 비밀번호
        passwordConfirmation: "", //# 비밀번호 확인
        registrationNumber: "", //# 사업자 등록 번호
        pickedCompType: "선택", //# 기업분류 선택값
        writeCompType: "", //# 기업분류 직접입력
        companyClassification: 0, //# 기업분류 보내는 값
        company_classification_etc: 0, //# 기업분류 보내는 값(직접 입력)
        companyName: "", //# 회사명
        owner: "", //# 대표명
        telephone: "", //# 유선전화
        name: "", //# 담당자 이름
        workDepartment: "", //# 근무 부서
        workPosition: "", //# 직책
        phone: null, //# 입력값 : 담당자 휴대전화 번호
        smsCode: null, //# 휴대폰 인증번호
        alertPhone: "", //# 알림 메시지 번호 (별도로 입력 시)
        // joinChannel: {0:false,1:false,2:false,3:false,4:false,5:false,6:false,7:false,8:false,9:false}, //# 유입 경로
        joinChannel: [false, false, false, false, false, false, false, false, false, false], //# 유입 경로
        join_channel_etc: "", //# 유입 경로 직접 입력
        joinResult: "",
      },
      // 유효성 검사 체크 완료된 항목
      dataCheck: {
        essentialAgree: 0, //# 필수 동의 체크
        email: false, //# 이메일
        registrationNumber: false, //# 사업자 등록 번호
        pickedCompType: false, //# 기업분류 선택값
        smsCode: false, //# 휴대폰 인증번호
        marketingAgree: false, //# 마케팅 동의
        companyAgree: false, //# 기업정보 약관 동의
        busiRegiNum: false, //# 사업자 등록 번호
      },
    };
  },
  mounted() {
    this.enterpriseUserRateApi();
    this.enterpriseUserTermApi();
    // 파일 입력 요소의 change 이벤트를 수신하기 위해 이벤트 리스너를 추가합니다.
    this.$refs.fileInput.addEventListener("change", this.handleFileChange);
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 이벤트 리스너를 제거합니다.
    this.$refs.fileInput.removeEventListener("change", this.handleFileChange);
  },
  updated() {
    if (
      this.userData.companyAgree == false ||
      this.userData.privacy == false ||
      this.userData.marketingAgree == false
    ) {
      this.totalCheck = false;
    } else if (
      this.userData.companyAgree == true ||
      this.userData.privacy == true ||
      this.userData.marketingAgree == true
    ) {
      this.totalCheck = true;
    }
  },
  watch: {
    // 유입경로 체크박스
    "userData.joinChannel": {
      handler: "updateResult",
      deep: true,
    },
  },
  methods: {
    closePopup(){
      this.isPopup = false;
    },
    // 유효성 검사
    validationCheck() {
      if (this.userData.companyAgree !== true) {
        this.pagepopup = true;
        this.alertMsg = "케어네이션 서비스 이용약관에 동의해주세요.";
        this.errLoca = this.$refs.agreeSection;
        return;
      } else if (this.userData.privacy !== true) {
        this.pagepopup = true;
        this.alertMsg = "케어네이션 개인정보 처리방침에 동의해주세요.";
        this.errLoca = this.$refs.agreeSection;
        return;
      } else if (Utils.isEmpty(this.userData.email)) {
        this.pagepopup = true;
        this.alertMsg = "이메일을 입력해주세요.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (!Utils.emailCheck(this.userData.email)) {
        this.pagepopup = true;
        this.alertMsg = "이메일 형식이 맞지 않습니다.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (this.dataCheck.email !== true) {
        this.pagepopup = true;
        this.alertMsg = "이메일 중복 확인을 해주세요.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (Utils.isEmpty(this.userData.password)) {
        this.pagepopup = true;
        this.alertMsg = "비밀번호를 입력해주세요.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (!Utils.passwordCheck(this.userData.password)) {
        this.pagepopup = true;
        this.alertMsg = "비밀번호 형식이 맞지 않습니다.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (Utils.isEmpty(this.userData.passwordConfirmation)) {
        this.pagepopup = true;
        this.alertMsg = "비밀번호 확인을 입력해주세요.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (!Utils.passwordCheck(this.userData.passwordConfirmation)) {
        this.pagepopup = true;
        this.alertMsg = "비밀번호 확인의 형식이 맞지 않습니다.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (this.userData.password !== this.userData.passwordConfirmation) {
        this.pagepopup = true;
        this.alertMsg = "비밀번호와 비밀번호 확인이 일치하지 않습니다.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (Utils.isEmpty(this.userData.registrationNumber)) {
        this.pagepopup = true;
        this.alertMsg = "사업자등록번호를 입력해주세요.";
        this.errLoca = this.$refs.registSection;
        return;
      } else if (this.userData.registrationNumber.length < 10) {
        this.pagepopup = true;
        this.alertMsg = "사업자등록번호는 10자리 입니다.";
        this.errLoca = this.$refs.registSection;
        return;
      } else if (this.dataCheck.registrationNumber !== true) {
        this.pagepopup = true;
        this.alertMsg = "사업자등록번호 인증을 진행해 주세요.";
        this.errLoca = this.$refs.registSection;
        return;
      } else if (this.file == null) {
        this.pagepopup = true;
        this.alertMsg = "사업자등록증 사본을 등록해주세요.";
        this.errLoca = this.$refs.registSection;
        return;
      } else if (this.userData.pickedCompType == "선택") {
        this.pagepopup = true;
        this.alertMsg = "기업분류를 선택해주세요.";
        this.errLoca = this.$refs.companySection;
        return;
      } else if (Utils.isEmpty(this.userData.companyName)) {
        this.pagepopup = true;
        this.alertMsg = "기업명을 입력해주세요.";
        this.errLoca = this.$refs.companySection;
        return;
      } else if (Utils.isEmpty(this.userData.owner)) {
        this.pagepopup = true;
        this.alertMsg = "대표자명을 입력해주세요.";
        this.errLoca = this.$refs.companySection;
        return;
      } else if (Utils.isEmpty(this.userData.telephone)) {
        this.pagepopup = true;
        this.alertMsg = "유선전화번호를 입력해주세요.";
        this.errLoca = this.$refs.companySection;
        return;
      } else if (Utils.isEmpty(this.userData.name)) {
        this.pagepopup = true;
        this.alertMsg = "담당자 이름을 입력해주세요.";
        this.errLoca = this.$refs.managerSection;
        return;
      } else if (this.dataCheck.smsCode !== true) {
        this.pagepopup = true;
        this.alertMsg = "휴대전화 번호 인증을 진행해주세요.";
        this.errLoca = this.$refs.managerSection;
        return;
      } else if (this.userData.joinResult == "") {
        this.pagepopup = true;
        this.alertMsg = "유입경로를 선택해주세요.";
        this.errLoca = this.$refs.funnelSection;
        return;
      } else if (this.userData.joinChannel[9] == true) {
        if (this.userData.join_channel_etc == "") {
          this.pagepopup = true;
          this.alertMsg = "기타 경로를 입력해주세요.";
          this.errLoca = this.$refs.funnelSection;
        } else {
          this.agreeCheck();
          this.signUpDone();
        }
      } else {
        this.agreeCheck();
        this.signUpDone();
      }
    },
    // 이메일 중복 조회
    checkDuplicateId() {
      if (Utils.isEmpty(this.userData.email)) {
        this.pagepopup = true;
        this.alertMsg = "이메일을 입력해주세요.";
        this.errLoca = this.$refs.infoSection;
        return;
      } else if (!Utils.emailCheck(this.userData.email)) {
        this.pagepopup = true;
        this.alertMsg = "이메일 형식이 맞지 않습니다.";
        this.errLoca = this.$refs.infoSection;
        return;
      }
      const emailWrap = { email: this.userData.email };
      this.checkDuplicateIdApi(emailWrap);
    },
    checkDuplicateIdApi(data) {
      Api.enterpriseCheckDuplicateId(data)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              // dispatch(showPopup(Popup, popupAction, "enterpriseNotify", "popup", "", data.message));
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
              this.dataCheck.email = true;
            } else {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = this.$refs.infoSection;
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          this.pagepopup = true;
          this.alertMsg = data.message;
          this.errLoca = "";
          console.log(err);
        });
    },
    // 약관동의 결과값 전달
    agreeCheck() {
      if (this.userData.companyAgree == true && this.userData.privacy == true) {
        this.dataCheck.essentialAgree = 3;
      } else {
        this.dataCheck.essentialAgree = 0;
      }
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name; // 파일 이름 설정
        this.file = files[0]; // 파일 데이터 설정
      } else {
        this.fileName = "";
        this.file = null;
      }
    },
    // 회원 통계 api
    enterpriseUserRateApi() {
      Api.enterpriseUserRate()
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.caremateData = data.data;
              this.makeChart();
            } else {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
            }
          } else {
            let data = response.data;
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.pagepopup = true;
          this.alertMsg = "오류가 발생했습니다. 잠시 후 시도해주세요.";
          this.errLoca = "";
        });
    },
    // 내외국인 차트
    makeChart() {
      const canvas = this.$refs.chart;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      const ctx = canvas.getContext("2d");
      const gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
      gradientFill.addColorStop(0, "#7e4f40");
      this.myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["내국인", "외국인"],
          datasets: [
            {
              data: [this.caremateData.local_rate, this.caremateData.foreigner_rate],
              backgroundColor: ["#7e4f40", "rgba(126, 79, 64, 0.31)"],
              fill: true,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      });
    },
    // 회원가입 약관 api
    enterpriseUserTermApi() {
      Api.enterpriseUserTerm("all")
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.termsData = data.data;
            } else {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = "오류가 발생했습니다. 잠시 후 시도해주세요.";
            this.errLoca = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.pagepopup = true;
          this.alertMsg = "오류가 발생했습니다. 잠시 후 시도해주세요.";
          this.errLoca = "";
        });
    },
    // 약관 접기펴기
    termsOnoff(picked) {
      this.termsToggle[picked] = !this.termsToggle[picked];
    },
    // 약관 전체 선택
    termsAllChk() {
      if (
        this.userData.companyAgree == true &&
        this.userData.privacy == true &&
        this.userData.marketingAgree == true
      ) {
        this.userData.companyAgree = false;
        this.userData.privacy = false;
        this.userData.marketingAgree = false;
      } else {
        this.userData.companyAgree = true;
        this.userData.privacy = true;
        this.userData.marketingAgree = true;
      }
    },
    // 휴대폰 번호 번호만 입력가능
    filterNonNumericInput() {
      if (this.userData.phone) {
        this.userData.phone = this.userData.phone.replace(/[^0-9]/g, "");
      } else if (this.userData.telephone) {
        this.userData.telephone = this.userData.telephone.replace(/[^0-9]/g, "");
      }
    },
    // 사업자 등록번호 인증 api
    authBusiNum(data) {
      const _data = { registration_number: data };
      Api.enterpriseUserRegistrationNumber(_data)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            // dispatch(showPopup(Popup, popupAction, "enterpriseNotify", "popup", "", data.message));
            if (data.code == 400) {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
            } else if (data.code == 200) {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
              this.dataCheck.registrationNumber = true;
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.pagepopup = true;
          this.alertMsg = data.message;
          this.errLoca = "";
        });
    },
    // 기업 타입 선택 이벤트
    compTypePick(picked) {
      if (picked == "직접 입력") {
        this.$refs.directTxt.disabled = false;
      } else {
        this.$refs.directTxt.disabled = true;
        this.$refs.directTxt.value = "";
      }
      this.$refs.dropBox.blur();
      if (this.userData.pickedCompType !== "직접 입력") {
        this.userData.pickedCompType = picked;
        if (picked == "협회") {
          this.userData.companyClassification = 1;
        } else if (picked == "병원") {
          this.userData.companyClassification = 2;
        } else if (picked == "요양원") {
          this.userData.companyClassification = 3;
        } else if (picked == "복지관") {
          this.userData.companyClassification = 4;
        }
      } else {
        this.userData.companyClassification = 5;
        this.userData.pickedCompType = this.userData.writeCompType;
        this.userData.company_classification_etc = this.userData.writeCompType;
      }
    },
    // 가입용 인증번호 발송
    sendSNSCode() {
      let jobj = {};
      jobj = {
        phone: this.userData.phone.replace(/-/g, ""),
      };
      this.enterpriseSendSMSApi(jobj);
    },
    enterpriseSendSMSApi(data) {
      Api.enterpriseSendSMS(data)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.certifiOn = true;
              this.disBox.phoneTxt = true;
              this.disBox.phoneBtn = true;
            } else {
              this.pagepopup = true;
              // this.alertMsg = data.message;
              this.alertMsg = "data.message";
              this.errLoca = "";
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          this.pagepopup = true;
          this.alertMsg = data.message;
          this.errLoca = "";
          console.log(err);
        });
    },
    // 인증번호 확인
    certSMSCode() {
      let jobj = {};
      jobj = {
        phone: this.userData.phone.replace(/-/g, ""),
        code: this.userData.smsCode,
      };
      this.enterpriseCertSMSApi(jobj);
    },
    enterpriseCertSMSApi(data) {
      Api.enterpriseCertSMS(data)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.disBox.certiTxt = true;
              this.disBox.certiBtn = true;
              this.dataCheck.smsCode = true;
            } else {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          this.pagepopup = true;
          this.alertMsg = data.message;
          this.errLoca = "";
          console.log(err);
        });
    },
    // 유입경로 체크박스 업데이트
    updateResult() {
      const checkedIndexes = [];
      this.userData.joinChannel.forEach((channel, idx) => {
        if (channel) {
          checkedIndexes.push(idx + 1);
        }
      });
      this.userData.joinResult = checkedIndexes.join(",");
    },
    // 유입경로 기타 텍스트 박스 리셋
    channelEtcReset() {
      this.userData.join_channel_etc = "";
    },
    // 회원 가입 전송
    signUpDone() {
      const formData = new FormData();
      formData.append("email", this.userData.email); //# 이메일
      formData.append("password", this.userData.password); //# 비밀번호
      formData.append("password_confirmation", this.userData.passwordConfirmation); //# 비밀번호 확인
      formData.append("registration_number", this.userData.registrationNumber); //# 사업자 등록 번호
      formData.append("company_classification", String(this.userData.companyClassification)); //# 기업 분류 (1, 2, 3, 4, 5: 직접입력)
      formData.append("company_classification_etc", this.userData.writeCompType); //# 기업 분류 직접입력
      formData.append("company_name", this.userData.companyName); //# 회사명
      formData.append("owner", this.userData.owner); //# 대표명
      formData.append("telephone", this.userData.telephone); //# 유선 전화
      formData.append("name", this.userData.name); //# 담당자 이름
      formData.append("work_department", this.userData.workDepartment); //# 근무부서
      formData.append("work_position", this.userData.workPosition); //# 직책
      formData.append("phone", this.userData.phone); //# 휴대폰
      formData.append("alert_phone", this.userData.alertPhone || this.userData.phone); //# 알림 메세지 번호
      formData.append("join_channel", String(this.userData.joinResult)); //# 유입경로
      formData.append("join_channel_etc", this.userData.join_channel_etc); //# 유입경로 직접입력
      formData.append("marketing_agree", Number(this.userData.marketingAgree)); //# 마케팅 정보 수신 동의
      formData.append("company_agree", String(this.dataCheck.essentialAgree)); //# 기업회원 서비스 약관/개인정보 처리방침 동의
      formData.append("code", this.userData.smsCode); //# 핸드폰 인증번호
      formData.append("registration", this.file || ""); //# 사업자 등록증 파일

      let email = this.userData.email;
      this.signUpApi(formData, email);
    },
    signUpApi(data, email) {
      Api.enterpriseSignUp(data, email)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "pageOut";
            } else {
              this.pagepopup = true;
              this.alertMsg = data.message;
              this.errLoca = "";
            }
          } else {
            this.pagepopup = true;
            this.alertMsg = data.message;
            this.errLoca = "";
          }
        })
        .catch((err) => {
          this.pagepopup = true;
          this.alertMsg = data.message;
          this.errLoca = "";
          console.log(err);
        });
    },
    // 알림 창 닫기 버튼
    popupOff() {
      this.pagepopup = false;
      if (this.errLoca !== "" && this.errLoca !== "pageOut") {
        this.errLoca.scrollIntoView({ behavior: "smooth" });
      } else if (this.errLoca == "pageOut") {
        this.$router.push("/service/company");
      } else {
        // console.log('err')
      }
    },
  },
};
</script>

<style></style>
